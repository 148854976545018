import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

interface Message {
  content: string;
  role: MessageRole.USER | MessageRole.ASSISTANT;
}

enum MessageRole {
  USER = 'user',
  ASSISTANT = 'assistant'
}

interface GameConfig {
  character: string;
  theme: string;
  tonality: string;
}

@Component({
    selector: 'app-root',
    imports: [
        FormsModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        CommonModule
    ],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private http = inject(HttpClient);
  private sanitizer = inject(DomSanitizer);
  private readonly API_URL = 'https://api.homerdavis.com/api/chat';
  private readonly PROMPT_PATH = 'assets/initial-prompt.txt';
  private readonly DEFAULT_CONFIG: GameConfig = {
    character: 'Rosco',
    theme: 'High Fantasy',
    tonality: 'Whimsical & Heroic'
  };

  messages: Message[] = [];
  initialPrompt = '';
  userInput = '';
  apiKey = '';
  setupStep = 0;
  character = this.DEFAULT_CONFIG.character;
  theme = this.DEFAULT_CONFIG.theme;
  tonality = this.DEFAULT_CONFIG.tonality;

  ngOnInit() {
    this.loadInitialPrompt();    
  }

  private loadInitialPrompt() {
    this.http.get(this.PROMPT_PATH, { responseType: 'text' })
    .subscribe(data => {
      this.initialPrompt = data;
      console.log('Initial prompt loaded:', this.initialPrompt);
      this.startSetup();
    });
  }

  private startSetup() {
    this.addMessageToHistory(
      "Welcome to AI Dungeons & Dragons! Let's set up your game.<br> Enter character name (example: Rosco the rogue halfling):",
      MessageRole.ASSISTANT);
  }

  private addMessageToHistory(content: string, role: MessageRole) {
    this.messages.push({ content, role });
  }

  private handleSetupStep() {
    const setupSteps: Record<number, () => void> = {
        0: () => {
            this.character = this.userInput || this.character;
            this.addMessageToHistory('Enter theme (example: High Fantasy):', MessageRole.ASSISTANT);
        },
        1: () => {
            this.theme = this.userInput || this.theme;
            this.addMessageToHistory('Enter tonality (example: Whimsical & Heroic):', MessageRole.ASSISTANT);
        },
        2: () => {
            this.tonality = this.userInput || this.tonality;
            this.addMessageToHistory('Enter your API Key:', MessageRole.ASSISTANT);
        },
        3: () => {
            this.apiKey = this.userInput;
            this.updateInitialPrompt();
            this.addMessageToHistory(
                `Perfect! Your game is set up with the following details:\n
                Character: ${this.character}\n
                Theme: ${this.theme}\n
                Tonality: ${this.tonality}\n
                Let's begin the adventure!`,
                MessageRole.ASSISTANT
            );
            this.getAIResponse();
        }
    };

    // Execute the setup function for current step if it exists
    setupSteps[this.setupStep]?.();
    this.setupStep++;
}
  
  sendMessage() {
    if (!this.userInput.trim()) return;

    this.addMessageToHistory(this.userInput, MessageRole.USER);

    if (this.setupStep < 4) {
      this.handleSetupStep();
    } else {
      this.getAIResponse();
    }

    this.userInput = '';
  }

  getSafeHtml(content: string) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  private updateInitialPrompt() {
    this.initialPrompt = this.initialPrompt
      .replace(/CHARACTER:.*/, `CHARACTER: ${this.character}`)
      .replace(/THEME:.*/, `THEME: ${this.theme}`)
      .replace(/TONALITY:.*/, `TONALITY: ${this.tonality}`);
  }

  private getAIResponse() {
    const formattedMessages = this.messages.map(msg => ({
      role: msg.role,
      content: msg.content.toString()
    }));

    // Clean up the API key
    this.apiKey = this.apiKey.replace(/[\u200B-\u200D\uFEFF\s]/g, '').trim();

    const body = {
      messages: formattedMessages,
      initialPrompt: this.initialPrompt,
      apiKey: this.apiKey
    };

    this.http.post(this.API_URL, body).subscribe({
      next: (response: any) => {
        if (response?.content?.[0]?.text) {
          const aiMessage = response.content[0].text;
          const formattedMessage = aiMessage.replace(/\n/g, '<br>');
          this.addMessageToHistory(formattedMessage, MessageRole.ASSISTANT);
        } else {
          console.error('Unexpected response structure:', response);
          this.addMessageToHistory('Sorry, I received an unexpected response. Please try again.', MessageRole.ASSISTANT);
        }
      },
      error: (error) => {
        console.error('Error:', error);
        this.addMessageToHistory('Sorry, I encountered an error. Please check your API Key, refresh and try again.' + error.toString(), MessageRole.ASSISTANT);
      }
    });
  }
}
