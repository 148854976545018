<div class="container mx-auto p-10 max-w-full h-screen flex flex-col">
  <h1 class="text-5xl font-medieval text-center mb-4 text-purple-400 shadow-glow">D&D AI Chatbot</h1>
  <div class="bg-gray-900 rounded-lg p-4 mb-4 flex-grow overflow-y-auto border-2 border-purple-700 shadow-magical">
    <div *ngFor="let message of messages" class="mb-4" [ngClass]="{'text-right': message.role === 'user'}">
      <div class="inline-block p-3 rounded-lg shadow-md" [ngClass]="message.role === 'user' ? 'bg-blue-900 text-blue-200' : 'bg-purple-900 text-purple-200'">
        <p [ngClass]="{'font-medieval': message.role === 'assistant'}"
           [innerHTML]="getSafeHtml(message.content)"></p>
      </div>
    </div>
  </div>
  <form (ngSubmit)="sendMessage()" class="flex mb-2" autocomplete="off">
    <!-- Hidden dummy input fields to block autocomplete -->
    <input type="text" name="username" style="display:none" aria-hidden="true">
    <input type="password" name="password" style="display:none" aria-hidden="true">
    <mat-form-field class="flex-grow mr-2 custom-input">
      <input matInput [(ngModel)]="userInput" name="userInput" placeholder="Cast your message..." class="w-full text-purple-200" autocomplete="off">
    </mat-form-field>
    <button mat-raised-button color="primary" type="submit" class="bg-purple-700 hover:bg-purple-600">
      <mat-icon>send</mat-icon>
    </button>
  </form>
</div>
